@import url("https://fonts.googleapis.com/css2?family=Caveat:wght@400;500;600;700&family=Hind+Siliguri:wght@300;400;500;600;700&display=swap");

:root {
  --background: #000b17;
  --primaryColor: #004742;
}

html,
*,
*::after,
*::before {
  box-sizing: border-box;
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-size: 16px;
  font-weight: normal;
  text-rendering: geometricPrecision;
  letter-spacing: 1.25px;
  /* text-transform: uppercase; */
  color: white;
  background: linear-gradient(90deg, var(--background), var(--primaryColor));
  font-family: "Hind Siliguri", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Caveat", cursive;
  text-align: center;
}

.app-cont {
  display: grid;
  gap: 1rem;
  justify-content: center;
  align-content: center;
  align-items: center;
  justify-items: center;
  width: 100vw;
  height: 100vh;
}

.cmike-logo {
  width: 40vw;
  height: auto;
}

p {
  text-align: center;
}
